<template>
  <div class="h-full w-full p-8 flex flex-col">
    <SearchTemplate
      :formData="formData"
      labelWidth="80px"
      :placeholderText="placeholder"
      @handleQuery="handleQuery"
      @handleReset="handleReset"
    >
      <el-button
        v-if="erpBinded"
        slot="right"
        type="primary"
        plain
        size="small"
        @click="toInvitation"
      >
        邀请本地往来单位
      </el-button>
      <template slot="more">
        <el-form-item label="加入日期">
          <el-date-picker
            v-model="joinTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="dateChangeFn"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所在地区">
          <SelectAreaMul @change="areaChangeFn" :areaData="formData.areaArr" />
        </el-form-item>
        <el-form-item label="单位类型">
          <el-select v-model="formData.type" clearable placeholder="单位类型">
            <el-option
              v-for="item in typeOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </SearchTemplate>
    <el-table
      :data="tableData"
      border
      class="table-base m-t-16"
      size="small"
      style="width: 100%"
      height="400px"
    >
      <el-table-column
        prop="cooperatorName"
        label="往来单位名称"
        min-width="180"
      >
        <template slot-scope="scope">
          <div class="flex justify-around px-8">
            <div class="unit_name">
              {{ renderName(scope.row) }}
            </div>
            <i
              class="iconfont icon-line_message1 cursor-pointer"
              @click="sendMsg(scope.row)"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="cooperatorPhone"
        align="center"
        label="联系电话"
        min-width="120"
      >
      <template slot-scope="scope">
          {{ scope.row.cooperatorPhone || scope.row.creatorPhone }}
        </template>
      </el-table-column>
      <el-table-column
        prop="cooperatorAreaName"
        align="center"
        label="所在地区"
        show-overflow-tooltip
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        prop="cooperatorType"
        align="center"
        label="用户类型"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ renderUserType(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="cooperatorTypeName"
        align="center"
        label="单位类型"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ renderCooperatorType(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="joinTimeStr"
        align="center"
        show-overflow-tooltip
        label="加入日期"
        min-width="120"
      >
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="180">
        <template slot-scope="scope">
          <div>
            <el-button type="text" size="mini" @click="settingFn(scope.row)">
              设置
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="m-t-16 text-right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageInfo.pageSize"
      layout="total,  prev, pager, next, jumper,sizes"
      :total="totalSize"
    >
    </el-pagination>
    <AuditUnitDialog
      ref="setting"
      :rowData="rowData"
      pageType="unitList"
      dialogTitle="设置"
      @refresh="refreshFn"
    />
    <InviteCooperDialog ref="invite" />
  </div>
</template>

<script>
import SearchTemplate from "@/components/search/SearchTemplate.vue";
import SelectAreaMul from "@/components/selectArea/SelectAreaMul.vue";
import AuditUnitDialog from "../components/auditUnitDialog.vue";
import InviteCooperDialog from "../components/inviteCooperDialog.vue";
import { getCooperatorList } from "@/api/cooperator";
import { mapState } from "vuex";

const defaultFormData = {
  joinStartTime: "", // # 加入时间起始
  joinEndTime: "", //# 加入时间截止
  keyword: "", //# 关键字
  areaArr: [], //所在地区
  type: "", //# 类型:0=个人,1=公司
};

export default {
  components: {
    SearchTemplate,
    SelectAreaMul,
    AuditUnitDialog,
    InviteCooperDialog,
  },
  data() {
    return {
      placeholder: "客户名称/联系人/电话等关键字",
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      joinTime: [],
      typeOptions: [
        { name: "个人", code: "person" },
        { name: "公司-经销商", code: "dealer" },
        { name: "公司-修理厂", code: "garage" },
        { name: "公司-生产厂家", code: "manufacturer" },
      ],
      pageInfo: {
        page: 1,
        pageSize: 20,
      },
      totalSize: 0,
      tableData: [
        // {
        //   id: 0, // # 自增ID
        //   companyId: 0, //# 公司ID
        //   cooperatorId: 0, // # 往来单位ID（用户id或公司id）
        //   cooperatorType: 1, // # 类型 0:个人，1:公司
        //   Flags: 0, //# 标记位: 第一位:是否是客户;第二位:是否是供应商;第三位:是否是物流公司;第四位:是否是保险公司;
        //   status: 0, //# 客户状态 0:待审核 1:正常 2:停用
        //   swCooperatorId: 0, // # 软件内往来单位ID
        //   joinTime: "2022-06-15 18:02:28", // # 加入时间/申请时间(待审核)
        //   createdBy: 0, //# 创建者员工ID
        //   updatedBy: 0, // # 更新者员工ID
        //   updatedAt: "0001-01-01T00:00:00Z", //	 # 更新时间
        //   cooperatorName: "测试公司-联系人", //# 单位名称
        //   cooperatorPhone: "13211111111", //# 电话
        //   cooperator_area_id: 0, // # 区域ID
        //   isCustomer: true, //# 是否是客户
        //   isSupplier: true, // # 是否是供应商
        //   isLogistics: false, // # 是否是物流公司
        //   isInsuranceCompany: false, // # 是否是保险公司
        //   swCooperatorName: "往来单位名称", //# 软件内往来单位名称
        //   cooperatorTypeName: "个人", //# 类型名称:个人/公司
        //   statusName: "", //# 状态名称:正常/停用
        //   companyType: "dealer", // # 往来单位是公司时，公司的类型： dealer=经销商 garage=修理厂 manufacturer=生产商
        // },
      ],
      rowData: {},
    };
  },
  computed: {
    ...mapState({
      erpBinded: (state) => state.profile?.erpBinded,
    }),
  },
  mounted() {
    this.initData();
  },
  methods: {
    renderName(item) {
      let str = "";
      if (item.cooperatorType === 0) {
        str +=
          item.cooperatorName +
          (item.swCooperatorName ? `（${item.swCooperatorName}）` : "");
      } else {
        str +=
          item.cooperatorName +
          // (item.creatorName ? `-${item.creatorName}` : "") +
          (item.swCooperatorName ? `（${item.swCooperatorName}）` : "");
      }
      return str;
    },
    renderUserType(item) {
      let str = "";
      if (item.cooperatorType) {
        if (item.companyType == "dealer") {
          str = "公司-经销商";
        } else if (item.companyType == "garage") {
          str = "公司-修理厂";
        } else if (item.companyType == "manufacturer") {
          str = "公司-生产厂家";
        }
      } else {
        str = "个人";
      }
      return str;
    },
    renderCooperatorType(item) {
      let arr = [];
      if (item.isSupplier) {
        arr.push("供应商");
      }
      if (item.isCustomer) {
        arr.push("客户");
      }

      // if (item.isLogistics) {
      //   arr.push("物流公司");
      // }
      // if (item.isInsuranceCompany) {
      //   arr.push("保险公司");
      // }
      return arr.join("、");
    },
    sendMsg(e) {
      this.$store.dispatch("setChatId", e.creatorId);
      this.$store.dispatch("changeIMVisible", true);
    },
    refreshFn() {
      this.pageInfo.page = 1;
      this.initData();
    },
    initData() {
      let params = {
        ...this.formData,
        ...this.pageInfo,
      };
      getCooperatorList(params).then((res) => {
        this.tableData = res?.rows || [];
        this.totalSize = res?.totalSize;
      });
    },
    handleQuery() {
      this.pageInfo.page = 1;
      this.initData();
    },
    handleReset() {
      this.formData = JSON.parse(JSON.stringify(defaultFormData));
      this.joinTime = [];
    },
    areaChangeFn(data) {
      this.formData.areaArr = data;
    },
    dateChangeFn(date) {
      if (date) {
        this.formData.joinStartTime = date[0];
        this.formData.joinEndTime = date[1];
      } else {
        this.formData.joinStartTime = "";
        this.formData.joinEndTime = "";
      }
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.initData();
    },
    settingFn(row) {
      console.log(row);
      this.rowData = row;
      this.$refs.setting.show();
    },
    toInvitation() {
      this.$refs.invite.show();
    },
  },
};
</script>

<style lang="less" scoped>
.unit_name {
  color: #6589f8;
}
.not_associat {
  display: inline-block;
  margin-top: 4px;
  color: #666666;
  padding: 0px 4px;
  border: 1px solid #999999;
  border-radius: 4px;
  background: #f5f5f5;
}
</style>
