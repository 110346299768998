<template>
  <div class="share-invite">
    <!-- 邀请码弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="inviteCodeDialog"
      :close-on-click-modal="false"
      @close="closedFn"
      top="20vh"
      width="500px"
    >
      <div class="container">
        <div class="mb-8">
          <span class="link-name">分享二维码</span>
        </div>
        <div class="flex">
          <div>
            <div class="share-left" ref="capture">
              <div class="flex m-t-8 m-l-8">
                <img
                  :src="profile.logoImage ? profile.logoImage : imgDefault"
                  alt="公司名片"
                />
                <div class="flex flex-col m-l-8">
                  <div>
                    {{ profile.companyName }}
                  </div>
                  <div>诚邀您的加入</div>
                </div>
              </div>
              <div class="flex flex-col items-center">
                <div id="qrcode" ref="qrcode"></div>
                <div class="remark m-t-16">扫描或长按识别二维码</div>
                <div class="remark m-t-32">来源： www.ubei.com</div>
                <div class="remark m-t-4">
                  有备平台-汽配行业免费的线上交流平台
                </div>
              </div>
            </div>
            <div class="desc">
              可下载二维码海报发送给您的客户或供应商，识别二维码进行注册账号绑定本地往来单位！
            </div>
          </div>

          <div class="flex flex-col m-l-16">
            <div>
              <el-button size="small" type="primary" @click="copyLink"
                >复制二维码
              </el-button>
            </div>
            <div class="m-t-8">
              <el-button size="small" type="primary" @click="copyCardLink"
                >复制卡片
              </el-button>
            </div>
            <div class="m-t-8">
              <el-button size="small" type="primary" @click="QRcodeDownload"
                >下载二维码
              </el-button>
            </div>
            <div class="m-t-8">
              <el-button size="small" type="primary" @click="posterDownload"
                >下载卡片
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCooperatorCode } from "@/api/cooperator";
import { mapState } from "vuex";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
export default {
  name: "Share",
  props: {
    rowData: Object,
  },
  data() {
    return {
      title: "往来单位邀请码-本地往来单位名称",
      inviteCodeDialog: false,
      codeUrl: "",
      imgDefault: require("@/assets/img/inquiry/place_40_40.jpg"),
      qrcode: null,
    };
  },
  watch: {
    rowData: {
      handler(val) {
        this.title = `往来单位邀请码-${val.swCustomerName}`;
      },
      deep: true,
    },
    inviteCodeDialog(val) {
      if (val) {
        setTimeout(() => {
          this.getCooperatorCode();
        }, 500);
      }
    },
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  methods: {
    // 将图片转换为Base64
    imageUrlToBase64(img) {
      // 一定要设置为let，不然图片不显示
      let image = new Image();
      // 解决跨域问题
      image.setAttribute("crossOrigin", "anonymous");
      // 使用js方式请求图片资源, 需要避免使用缓存，设置url后加上时间戳，或者http头设置Cache-Control为no-cache
      let imageUrl = img + "&time=" + new Date().getTime();
      image.src = imageUrl;
      // image.onload为异步加载
      image.onload = () => {
        this.getImage(image);
      };
    },
    getImage(image) {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let quality = 0.8;
      // 这里的dataurl就是base64类型
      this.profile.logoImage = canvas.toDataURL("image/jpeg", quality);
    },
    qrcodeScan(text) {
      //生成二维码
      if (this.qrcode) {
        this.qrcode.clear();
        this.qrcode.makeCode(text);
      } else {
        this.qrcode = new QRCode("qrcode", {
          width: 80, // 设置宽度，单位像素
          height: 80, // 设置高度，单位像素
          text, // 设置二维码内容或跳转地址
        });
      }
    },
    getCooperatorCode() {
      let params = {
        sceneKey: 1,
        sceneValue: this.rowData.swCustomerId,
      };
      getCooperatorCode(params).then((res) => {
        this.codeUrl = res?.url;
        this.qrcodeScan(this.codeUrl);
        this.imageUrlToBase64(
          this.profile.logoImage ? this.profile.logoImage : this.imgDefault
        );
      });
    },
    show() {
      this.inviteCodeDialog = true;
    },
    closedFn() {
      this.inviteCodeDialog = false;
    },
    copyLink() {
      //复制二维码
      var _this = this;
      html2canvas(this.$refs.qrcode).then((canvas) => {
        let link = document.createElement("img");
        let base64Url = canvas.toDataURL("image/png"); //base64
        let blobUrl = this.dataURLtoBlob(base64Url);
        link.src = URL.createObjectURL(blobUrl); //下载链接
        _this.copyCard(link.src);
      });
    },
    posterDownload() {
      //下载卡片
      this.$nextTick(() => {
        html2canvas(this.$refs.capture).then((canvas) => {
          let link = document.createElement("a");
          let base64Url = canvas.toDataURL("image/png"); //base64
          let blobUrl = this.dataURLtoBlob(base64Url);
          link.href = URL.createObjectURL(blobUrl); //下载链接
          link.setAttribute("download", `${this.profile.companyName}海报.png`);
          link.style.display = "none"; //a标签隐藏
          document.body.appendChild(link);
          link.click();
        });
      });
    },

    copyCardLink() {
      var _this = this;
      this.$nextTick(() => {
        html2canvas(this.$refs.capture).then((canvas) => {
          let link = document.createElement("img");
          let base64Url = canvas.toDataURL("image/png"); //base64
          let blobUrl = this.dataURLtoBlob(base64Url);
          link.src = URL.createObjectURL(blobUrl); //下载链接
          // console.log("link", link.src);
          // console.log("link", link);
          _this.copyCard(link.src);
          // _this.$copyText(link.src).then(
          //   function() {
          //     _this.$message({
          //       message: "卡片链接已复制到剪贴板",
          //       type: "success",
          //     });
          //   },
          //   function() {
          //     _this.$message({
          //       message: "复制失败",
          //       type: "error",
          //     });
          //   }
          // );
        });
      });
    },
    // 复制图片，支持jpg，png，传入图片url即可
    copyCard(url) {
      var canvas = document.createElement("canvas"); // 创建一个画板
      let image = new Image();
      image.setAttribute("crossOrigin", "Anonymous"); //可能会有跨越问题
      image.src = url;
      image.onload = () => {
        // img加载完成事件
        canvas.width = image.width; //设置画板宽度
        canvas.height = image.height; //设置画板高度
        canvas.getContext("2d").drawImage(image, 0, 0); //加载img到画板
        let url = canvas.toDataURL("image/png"); // 转换图片为dataURL，格式为png
        this.clipboardImg(url); // 调用复制方法
      };
    },
    // 从url下载并复制图片，如果是png格式的图片直接调用此方法就能复制成功
    async clipboardImg(url) {
      let _this = this;
      try {
        const data = await fetch(url);
        const blob = await data.blob();
        await navigator.clipboard.write([
          new window.ClipboardItem({
            [blob.type]: blob,
          }),
        ]);
        _this.$message({
          message: "已复制到剪贴板",
          type: "success",
        });
        // alert("复制成功");
      } catch (err) {
        console.log("复制失败", err);
      }
    },
    //将base64转换为blob对象 解决低版本浏览器兼容问题
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(",");
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var mime = arr[0].match(/:(.*?);/)[1];
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    QRcodeDownload() {
      //仅下载二维码
      html2canvas(this.$refs.qrcode).then((canvas) => {
        let link = document.createElement("a");
        link.href = canvas.toDataURL(); //下载链接
        link.setAttribute("download", `${this.profile.companyName}二维码.png`);
        link.style.display = "none"; //a标签隐藏
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.share-invite {
  .container {
    display: flex;
    flex-direction: column;
    padding: @padding-size-main;
    background: #ffffff;
    .share-left {
      width: 300px;
      //   height: 504px;
      display: flex;
      flex-direction: column;
      padding: @padding-size-main;
      box-sizing: border-box;
      background: #f9f9f9;
      border: 1px solid #f2f2f2;
      box-shadow: 5px 5px 5px rgba(185, 185, 185, 0.24);
      border-radius: 7px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        object-fit: cover;
      }

      #qrcode {
        // width: 80px;
        // height: 80px;
        margin-top: 16px;
        // border: 1px solid @border-color-base;
        display: inline-block;
        // padding: 4px;
        background-color: #fff;
      }

      .remark {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #c6c6c6;
        line-height: 20px;
        font-weight: 400;
      }
    }
    .desc {
      width: 300px;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #a8a8a8;
      // line-height: 20px;
      font-weight: 400;
      margin-top: 16px;
    }
  }
}
</style>
