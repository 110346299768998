<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="80%">
    <div>
      <SearchTemplate
        :formData="formData"
        labelWidth="80px"
        class="search_fans"
        :placeholderText="placeholder"
        @handleQuery="handleQuery"
        @handleReset="handleReset"
      >
        <template slot="more">
          <el-form-item label="是否已绑定">
            <el-select v-model="formData.isBind" placeholder="是否已绑定">
              <el-option
                v-for="item in isBindOptions"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位类型">
            <el-select
              class="flex-1"
              v-model="formData.swCustomerType"
              size="small"
              placeholder="单位类型"
            >
              <el-option
                v-for="item in swCustomerTypeOptions"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </SearchTemplate>
      <el-table
        :data="tableData"
        border
        class="xtable m-t-16 invite_table"
        size="small"
        style="width: 100%"
        height="400"
      >
        <el-table-column prop="swCustomerName" label="本地往来单位名称">
          <template slot-scope="scope">
            <div>
              <div class="unit_name">
                {{ scope.row.swCustomerName }}
                <!-- <span v-show="scope.row.swCooperatorName"
                  >({{ scope.row.swCooperatorName }})</span
                > -->
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="contactPhone"
          align="center"
          label="联系电话"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="area" align="center" label="所在地区">
          <template slot-scope="scope">
            {{ renderArea(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="cooperatorTypeName"
          align="center"
          label="单位类型"
        >
          <template slot-scope="scope">
            {{ renderCooperatorType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="cooperatorName"
          align="center"
          label="绑定的平台往来单位"
        >
          <template slot-scope="scope">
            <div class="flex justify-around px-8">
              <div class="unit_name">
                {{ renderCooperatorName(scope.row.cooperatorList) }}
              </div>
              <!-- <i
                class="iconfont icon-line_message1 cursor-pointer"
                @click="sendMsg(scope.row)"
              ></i> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <div
              v-if="
                !(scope.row.cooperatorList && scope.row.cooperatorList.length)
              "
            >
              <el-button
                type="text"
                size="mini"
                @click="inviteCodeFn(scope.row)"
              >
                邀请码
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="m-t-16 text-right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageInfo.pageSize"
        layout="total,  prev, pager, next, jumper,sizes"
        :total="totalSize"
      >
      </el-pagination>
    </div>
    <InviteCodeDialog ref="inviteCode" :rowData="rowData" />
  </el-dialog>
</template>

<script>
import SearchTemplate from "@/components/search/SearchTemplate.vue";
import InviteCodeDialog from "./inviteCodeDialog.vue";
import { getCooperatorErpList } from "@/api/cooperator";

const defaultFormData = {
  keyword: "",
  // swCustomerName: "", //	 # 非必填 模糊匹配
  // swCustomerIds: "", //# 非必填 通过本地往来单位客户ID查询,英文逗号分割的id
  // swPhone: "", //# 非必填 手机号
  swCustomerType: "customer-or-supplier", //	 # 非必填 往来单位类型 customer：客户(默认)； supplier：供应商； logistics：物流公司； storage：仓储公司； insuranceCompany：保险公司； all：全部
  isBind: 0, //# 是否绑定:1=已绑定的，-1=未绑定的，其他=全部
  // page: 0, //# 页码
  // pageSize: 0, // # 页大小
};
export default {
  components: {
    SearchTemplate,
    InviteCodeDialog,
  },
  data() {
    return {
      dialogTitle: "邀请本地往来单位",
      placeholder: "往来单位名称/联系人等关键字",

      formData: JSON.parse(JSON.stringify(defaultFormData)),
      isBindOptions: [
        { name: "全部", code: 0 },
        { name: "是", code: 1 },
        { name: "否", code: -1 },
      ],
      swCustomerTypeOptions: [
        { name: "全部", code: "customer-or-supplier" },
        { name: "客户", code: "customer" },
        { name: "供应商", code: "supplier" },
      ],
      pageInfo: {
        page: 1,
        pageSize: 20,
      },
      totalSize: 0,
      tableData: [
        // {
        //   swCustomerId: 0, // # 往来单位ID
        //   swCustomerName: "成都大气汽配经销商", // # 本地往来单位名称
        //   related: false,
        //   province: "四川省", // # 省
        //   city: "成都市", //# 市
        //   area: "双流区", // # 区县
        //   contactName: "联系人", // # 联系人
        //   contactPhone: "13422222222", // # 联系人电话
        //   isCustomer: true, //	 # 是否是客户
        //   isSupplier: true, //	 # 是否是供应商
        //   isLogistics: false, // # 是否是物流公司
        //   isInsuranceCompany: false, //# 是否是保险公司
        //   cooperatorId: 0, // # 关联的平台往来单位ID
        //   cooperatorName: "", // 关联的平台往来单位名称
        // },
      ],
      dialogVisible: false,
      rowData: {},
    };
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.initData();
      }
    },
  },
  methods: {
    renderCooperatorName(list) {
      return list?.length
        ? list.map((item) => item.cooperatorName).join(",")
        : "";
    },
    renderArea(item) {
      let str = "";
      str =
        (item.province ? item.province + "/" : "") +
        (item.city ? item.city + "/" : "") +
        (item.area ? item.area : "");
      // let arr = [item.province, item.city, item.area];
      return str;
    },
    sendMsg(e) {
      this.$store.dispatch("setChatId", e.swCustomerId);
      this.$store.dispatch("changeIMVisible", true);
    },
    renderCooperatorType(item) {
      let arr = [];
      if (item.isSupplier) {
        arr.push("供应商");
      }
      if (item.isCustomer) {
        arr.push("客户");
      }

      // if (item.isLogistics) {
      //   arr.push("物流公司");
      // }
      // if (item.isInsuranceCompany) {
      //   arr.push("保险公司");
      // }
      return arr.join("、");
    },
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    initData() {
      let params = {
        ...this.formData,
        swCustomerName: this.formData.keyword,
        ...this.pageInfo,
      };
      delete params.keyword;
      getCooperatorErpList(params).then((res) => {
        this.tableData = res?.rows || [];
        this.totalSize = res?.totalSize;
      });
    },
    handleQuery() {
      this.pageInfo.page = 1;
      this.initData();
    },
    handleReset() {
      this.formData = JSON.parse(JSON.stringify(defaultFormData));
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.initData();
    },
    inviteCodeFn(row) {
      this.rowData = row;
      this.$refs.inviteCode.show();
    },
  },
};
</script>

<style lang="less" scoped>
.unit_name {
  color: #6589f8;
}
.invite_table {
  /deep/.el-table__body-wrapper {
    height: 358px !important;
  }
}
</style>
